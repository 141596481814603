import VueRouter from 'vue-router'
import Main from '../views/Main.vue'

const routes = [
    {
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        path: '**',
        redirect: '/'
    }
]

const router = new VueRouter({
    base: 'physical',
    routes,
    mode: 'history'
})

export default router
