import axios from 'axios';

export default {
    /**
     * Получение основных данных по заказу справок
     * @returns {Promise<AxiosResponse<any>>}
     */
    getInitData() {

        return axios.get('/physical/init');
    },
    /**
     * Заказ/генерация справки
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getDocument(data) {

        return axios.post('/physical/get-document', data);
    },
    /**
     * Получение списка счетов для звказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAccounts(data) {

        return axios.post('/physical/get-accounts', data);
    },
    getCards(data) {

        return axios.post('/physical/get-cards', data);
    },

    getCredits(data) {
        return axios.post('/physical/get-credits', data);
    },

    getDeposits(data) {
        return axios.post('/physical/get-deposits', data);
    },

    getEmail() {
        return axios.get('/physical/get-email');
    },

    searchEmployee(data) {
        return axios.post('/physical/search-employee', data);
    },
    /**
     * Получение списка дополнительных полей для заказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAdditionElements(data) {

        return axios.post('/physical/get-additional-elements', data);
    }
}
