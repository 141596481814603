<template>
  <div class="custom-select-component" :tabindex="tabindex" @blur="open = false">
    <div ref="input" class="selected" :class="{ open: open }" @click="openSelect()">
      <div>
        {{ label }}
      </div>
    </div>
    <div ref="items" class="items" :class="{selectHide: open}" :style="itemsStyle">
      <div
        class="item"
        :class="{ disabled: option && option.disabled, hidden: option && option.hidden}"
        v-for="(option, i) of options"
        :key="i"
        @click="onBtnClick(option)"
      >
        <div v-if="option">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
          ? this.default
          : this.options.length > 0
              ? this.options[0].value
              : null,
      open: false,
      label: this.default
          ? this.default
          : this.options.length > 0
              ? this.options[0].label
              : null,
      itemsStyle: {}
    };
  },

  watch: {
    options: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setValue(newVal[0]);
      }
    },
  },

  methods: {
    openSelect() {
      this.open = !this.open
      if (this.open) {
        setTimeout(() => {
          const heightDiff = window.innerHeight - this.$refs.input.getBoundingClientRect().bottom;
          const itemsHeight = this.$refs.items.getBoundingClientRect().height;
          const heightGap = heightDiff - itemsHeight;
          const inputHeight = this.$refs.input.getBoundingClientRect().height;
          if (heightGap < 0) {
            this.itemsStyle = {opacity: 1, top: -itemsHeight + 'px', flexDirection: 'column-reverse'}
          } else {
            this.itemsStyle = {top: inputHeight, opacity: 1}
          }
        }, 0)
      }
    },
    onBtnClick(option) {
      if (!option.disabled) {
        this.setValue(option);
      }
    },

    setValue(option) {
      this.selected = option.value;
      this.label = option.label;
      this.open = false;
      this.$emit('input', option.value)
    }
  },

  mounted() {
    this.$emit("input", this.selected);
  },

};
</script>

<style scoped>

  .custom-select-component {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
  }

  .custom-select-component .selected {
    background-color: #fff;
    border-radius: 0;
    border-bottom: 1px solid #464646;
    color: #303030;
    opacity: 0.7;
    cursor: pointer;
    user-select: none;
  }

  .custom-select-component .selected div{
    padding-left: 1em;
    margin-right: 3em;
    overflow: hidden;
    white-space: nowrap;
  }

  .custom-select-component .selected.open {
    overflow: hidden;
    white-space: nowrap;
  }

  .custom-select-component .selected:after {
    position: absolute;
    content: "";
    top: 22px;
    right: 1em;
    width: 15px;
    height: 6px;
    background: url("../../../assets/image/arrow.svg") no-repeat center / contain;
    transform: rotate(180deg);
    transition: all ease-in-out 0.1s;
  }

  .custom-select-component .selected.open:after {
    transform: rotate(0);
  }

  .custom-select-component .items {
    background: #FFFFFF;
    border-radius: 3px;
    overflow: hidden;
    position: absolute;
    left: 0;
    display: none;
    opacity: 0;
    flex-direction: column;
    z-index: 100;
  }

  .custom-select-component .items .item {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    user-select: none;
    min-width: 300px;
  }

  .custom-select-component .items .item.disabled {
    cursor: initial;
  }

  .custom-select-component .items .item.hidden {
    display: none;
  }

  .custom-select-component .items .item div{
    padding-left: 1em;
    margin-right: 1em;
    overflow: hidden;
    white-space: nowrap;
  }

  .custom-select-component .items .item:hover {
    background-color: #00D352;
  }
  .custom-select-component .items .item.disabled:hover {
    background-color: #fff;
  }

  .custom-select-component .items.selectHide {
    display: flex;
    border: 1px solid #E6E6E6;
    box-shadow: 3px 4px 12px rgba(94, 1, 1, 0.13);
  }
</style>
