<template>
  <div class="spinner-bg" v-if="show">
    <div id="apple" class="spinner">
      <svg id="leaf" viewBox="0 0 13 13" data-name="apple-leaf" xmlns="http://www.w3.org/2000/svg">
        <path d="M0,0.0039C7.0092,-0.047 10.2217,0.3257 10.0505,5.3178C9.9511,5.4491 9.8561,5.582 9.7666,5.7177C8.8234,3.9102 6.753,2.3261 2.4763,1.4761C5.5888,2.5721 8.2125,4.0156 9,7C8.5,7.5 8.5,7.5 7.7592,8.0898C1.3691,8.6819 0.8571,5.8828 0,0.0039ZM12.0258,5.2713C13.0132,4.5202 14.3723,5.4766 12.9563,6.3436C12.2058,6.8031 11.0161,7.5323 9.8956,8.3134C8.4635,9.3118 7.1444,10.395 7,11.1083C7.1528,9.7182 8.1838,8.4619 9.4086,7.3575C10.2642,6.586 11.2143,5.8886 12.0258,5.2713Z"/>
      </svg>
      <svg id="body" data-name="apple-body" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
        <path id="bg" d="M11.46,2.21a5.42,5.42,0,0,0,1.14.08,5.05,5.05,0,0,0,1.15-.22,9.82,9.82,0,0,1,8.08,1.32c2.78,2,3.52,5.53,3,9-.6,4.17-4.52,12.33-9.57,10.3a4.64,4.64,0,0,0-1.91-.43,9.81,9.81,0,0,0-2,.52C5.65,24.2,1.54,16.24,1.07,11.54c-.31-3,.41-6,2.68-7.77a9.7,9.7,0,0,1,7.71-1.55Z"/>
        <path id="fill" d="M11.46,2.21a5.42,5.42,0,0,0,1.14.08,5.05,5.05,0,0,0,1.15-.22,9.82,9.82,0,0,1,8.08,1.32c2.78,2,3.52,5.53,3,9-.6,4.17-4.52,12.33-9.57,10.3a4.64,4.64,0,0,0-1.91-.43,9.81,9.81,0,0,0-2,.52C5.65,24.2,1.54,16.24,1.07,11.54c-.31-3,.41-6,2.68-7.77a9.7,9.7,0,0,1,7.71-1.55Z"/>
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: "Spinner",
  props: {
    show: {
      type: Boolean,
      required: true,
    }
  }
}
</script>

<style scoped>
  #apple {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
  }
  #bg {
    fill:  none;
    stroke: #64b22c;
    stroke-opacity: 0.1;
  }
  #fill {
    fill:  none;
    stroke: #00D352;
    stroke-opacity: 1;
    stroke-dasharray: 38px, 38px;
    stroke-dashoffset: -1;
    animation: fillApple 0.7s linear infinite;
  }

  #leaf {
    fill:  #00D352;
    width: 30%;
    height: 30%;
    margin-bottom: -5%;
    animation: rotateLeaf 1.2s linear infinite;
  }

  @keyframes fillApple {
    0% {
      stroke-dashoffset: -1;
    }
    100% {
      stroke-dashoffset: -75;
    }
  }

  @keyframes rotateLeaf {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

</style>
