<script>
import '../styles/legal/index.scss'

export default {
  data() {
    return {};
  },
  mounted() {
    document.body.classList.remove('physical-container');
    document.body.classList.add('legal-container');
  }
}
</script>

<template>
  <div class="container-fluid">
    <router-view></router-view>
  </div>
</template>
