<template>
  <div :class="display ? 'display' : (data ? 'display' : 'hidden')">
    <table class="table" aria-describedby="table">
      <tr class="table-header">
        <th id="radio" class="radio-btn">
          <b-form-checkbox
              :checked="getAllChecked()"
              @change="setAllChecked(parameter, element, $event)"
              :name="name"
              v-if="multiple && data.length"
          />
        </th>
        <th :id="header.field" v-for="header in headers" :key="header.field">
          {{ header.header }}
        </th>
      </tr>
      <tbody class="table-body-empty" v-if="loading">
        <Spinner :show="true" class="right-side-spinner"></Spinner>
      </tbody>
      <tbody class="table-body" v-if="!loading">
        <div class="table-body-no-data" v-if="!data.length">
          {{element.no_data_label || noDataTitle}}
        </div>
        <tr v-for="(item, index) in data" :key="index">
          <td class="radio-btn" v-if="!multiple">
            <b-form-radio
                :value="getRequestParam(parameter, name, item, multiple)"
                v-model="selected"
                @change="setRequestParam(parameter, name, $event, item, multiple)"
                name="selected"/>
          </td>
          <td class="radio-btn" v-if="multiple">
            <b-form-checkbox
                :value="getRequestParam(parameter, name, item, multiple)"
                @change="setRequestParam(parameter, name, $event, item, multiple)"
                :name="name"
                :checked="selected"/>
          </td>
          <td v-for="(value, index) in element.columns" :key="index">{{ item[value.field] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Spinner from "@/physical/components/spinner/Spinner";
import {tableParamsForRequest} from "@/physical/enums/tableParamsForRequest.enum.ts";

export default {
  name: "CustomTable",
  components: {
    Spinner,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameter: {
      type: String,
      required: true,
      default: 'param',
    },
    element: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    name: {
      type: String,
      required: true,
      default: 'table',
    },
    multiple: {
      type: Boolean,
      required: true,
      default: false,
    },
    isReset: {
      type: Boolean,
      required: false,
      default: false,
    },
    noDataTitle: {
      type: String,
      required: true,
      default: 'No Data',
    },
    data: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    headers: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      selected: [],
      previouslySelected: [],
      paramsTypeForGetValue: tableParamsForRequest,
    }
  },
  methods: {
    getAllChecked() {
      return this.selected.length === this.data.length;
    },

    setAllChecked(param, element, event) {
      this.selected = event ? element.value.map(el => el[this.paramsTypeForGetValue[element.name]]) : [];
      this.$emit("change", this.selected);
    },

    getRequestParam(param, type, item, multiply) {
      if (!multiply) {
        return item[type];
      } else {
        return this.selected.find(el => el === item[type]);
      }
    },

    setRequestParam(param, type, value, item, multiply) {
      if (multiply) {
        const elem = this.selected.find(el => el === item[type]);
        elem ? this.selected.splice(this.selected.indexOf(elem), 1) : this.selected.push(item[type]);
        this.$emit("change", this.selected);
      } else {
        this.$emit("change", value);
      }
    }
  },
  watch: {
    isReset: function (newValue) {
      if (this.selected.length && newValue) {
        this.selected = [];
      }
    }
  }
}
</script>

<style lang="scss">
.physical {
  table.table {
    width: auto;
  }
  .table-header {
    color: #3b414b;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }

  .table-body {
    position: relative;
    min-height: 48px;
    height: 48px;
    &-no-data {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-empty {
      position: relative;
      min-height: 100px;
      height: 100px;
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 0;
    border-radius: 5px;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);
    border: 1px solid #F3F3F3;
    width: auto;
    th {
      text-align: center;
      border-top: solid 1px #00D352 !important;
      border: solid 1px #00D352;
      border-style: solid none;
    }
    th:first-child {
      border-top: solid 1px #00D352;
      border-left-style: solid;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    th:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    td {
      text-align: center;
      border-top: none !important;
    }

    td, th {
      width: 212px;
      @media only screen and (max-width: 1376px ) {
        width: 144px;
      }
    }
    #radio,
    .radio-btn {
      width: 60px;
      max-width: 60px;
      min-width: 60px;
    }
  }
}
</style>
