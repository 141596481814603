import Vue from 'vue';
import axios from 'axios';
import LegalRouter from "@/legal/router";
import PhysicalRouter from "@/physical/router";

const router = location.pathname.includes('/legal') ? LegalRouter : PhysicalRouter;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.headers.post['Content-Type'] = 'application/json';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent

    /**
     * to test with token on local
     * const token = '';
     *  config['headers'] = {Authorization: `Bearer ${token}`};
     */


    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (error.response.status === 401) {
        if (error.response.config.url.includes('/legal/')) {
            router.push({path: 'error'});
        }
    } else if (error.response.status === 422) {
        if (error.response.config.url.includes('/legal/')) {
            return Promise.reject({errors: error.response.data});
        }
        return Promise.reject({errors: error.response.data.errors});
    } else {
        let message;
        if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
        } else if (error.error && error.error.message) {
            message = error.error.message;
        } else {
            message = 'Сталася помилка'
        }
        if (error.response.config.url.includes('/search-employee')) {
            Vue.$toast.clear()
        }
        Vue.$toast.open({
            type: 'error',
            message: message,
            position: 'top-right',
            duration: 5000
        });
    }

    return Promise.reject(error);
});

export default axios;
