<template>
  <div>
    <DateRangePickerComponent
        v-model="datePeriod"
        range
        :lang="config.lang"
        :format="config.format"
        :range-separator="config.separator"
        :disabled-date="disabledDates"
        @input="emit">
      <div slot="header" slot-scope="header" class="daterangepicker-header">
        <div class="start-date">
          {{getDateRangePickerDate(header, 'startDate')}}
        </div>
        <div class="separator">
          -
        </div>
        <div class="end-date">
          {{getDateRangePickerDate(header, 'endDate')}}
        </div>
      </div>
      <img slot="icon-calendar" src="../../../assets/image/datepicker_img.svg" alt="calendar">
    </DateRangePickerComponent>
    <div class="period-btns-wrapper" v-if="periodButtonsData">
      <button v-for="button in periodButtonsData.buttonsLabel"
              :key="button"
              :class="isPeriodActive([periodButtonsData.buttons[button].date_from, periodButtonsData.buttons[button].date_to]) ? 'active' : ''"
              @click="setDatePeriod([periodButtonsData.buttons[button].date_from, periodButtonsData.buttons[button].date_to])">
        {{ periodButtonsData.buttons[button].label }}
      </button>
    </div>
  </div>
</template>

<script>
import DateRangePickerComponent from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  name: "DateRangePicker",
  components: {
    DateRangePickerComponent,
  },
  props: {
    config: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    periodButtonsData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    min: {
      type: Date,
      required: false
    }
  },
  data() {
    return {
      datePeriod: this.config.defaultValue
    }
  },
  methods: {
    getDateRangePickerDate(data, date) {
      const dates = {
        startDate: data.value[0],
        endDate: data.value[1]
      };
      const outputDate = this.dateFormatter(dates[date], "D MMMM, YYYY");
      return outputDate !== 'Invalid date' ? outputDate : '';
    },
    dateFormatter(date, format) {
      return moment(date, 'DD-MM-YYYY').local('uk').format(format);
    },
    emit() {
      this.$emit('input', this.datePeriod);
    },
    disabledDates(date) {
      const today = new Date();
      if (this.min) {
        return date > today || date < this.min;
      }
      return date > today;
    },
    setDatePeriod(value)  {
      this.datePeriod = value.map(el => new Date(el));
      this.emit();
    },
    isPeriodActive(period) {
      return moment(this.datePeriod[0]).format('YYYY-MM-DD') === period[0] && moment(this.datePeriod[1]).format('YYYY-MM-DD') === period[1];
    }
  },
  mounted() {
    this.emit();
  }
}
</script>

<style lang="scss">
  .mx-icon-calendar {
    width: 20px;
    height: 20px;
    top: 45%;
    right: 30px;
    img {
      width: 100%;
    }
  }
  .mx-icon-clear{
    right: 30px;
  }

  .mx-datepicker-header {
    padding: 0;
    .daterangepicker-header {
      display: flex;
      border-radius: 10px 10px 0 0;
      padding: 20px;
      font-size: 24px;
      font-weight: 600;
      line-height: 100%;
      .separator {
        padding: 0 8px;
      }
    }
  }

.physical-container {
  .period-btns-wrapper {
    display: flex;
    column-gap: 22px;
    margin-top: 16px;
    button {
      box-shadow: none;
      border: none;
      border-radius: 5px;
      padding: 4px 10px;
      background-color: #f5f5f5;
      color: #5e5e5e;
      &:hover {
        color: #fff;
        background-color: #00D352;
      }
      &.active {
        color: #fff;
        background-color: #00D352;
      }
    }
  }
  .date-select,
  .mx-datepicker-range {
    width: 100%;
    .mx-input-wrapper {
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      .mx-input {
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        text-align: left;
        color: #495057;
        border-radius: 0;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #464646;
        min-width: 250px;
      }
      .mx-icon-calendar {
        right: 6px;
      }
      .mx-icon-clear{
        right: 6px;
      }
    }
  }
  .mx-datepicker-main {
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 8px rgba(50,50,93,.2);
    .mx-datepicker-content {
      .mx-datepicker-body {
        .mx-btn-icon-double-left,
        .mx-btn-icon-double-right {
          display: none;
        }
        button.mx-btn:hover {
          color: #00D352;
          border-color: #00D352;
        }
      }

      .cell {
        position: relative;
        z-index: 100;
        &.active {
          color: #fff;
          border: none;
          background: #00D352;
          background-color: #00D352;
          border-radius: 50px;
        }
        &:not(.disabled):not(.active):not(.in-range):hover {
          color: #fff;
          border: none;
          background-color: #00D352;
          border-radius: 50px;
        }
        &.disabled {
          cursor: auto;
        }
        &.today {
          color: #00D352;
          &.active {
            color: #fff;
          }
        }
        &.in-range,
        &.hover-in-range {
          background-color: rgba(0, 211, 82, 0.29);
        }
      }

      .hover-in-range + .cell:not(.disabled):not(.active):not(.in-range):hover::before,
      .in-range + .cell.active::before,
      .hover-in-range + .cell.active::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 35%;
        background-color: rgba(0, 211, 82, 0.29);
        z-index: 5;
      }

      .cell:not(.disabled):not(.active):not(.in-range):hover + .hover-in-range::before,
      .cell.active + .in-range::before,
      .cell.active + .hover-in-range::before {
        content: "";
        position: absolute;
        top: 0;
        left: -35%;
        height: 100%;
        width: 35%;
        background-color: rgba(0, 211, 82, 0.29);
        z-index: 5;
      }


      .mx-datepicker-header {
        .daterangepicker-header {
          background-color: #00d352;
          color: #fff;
        }
      }
    }
  }
}
</style>
