import VueRouter from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('../views/Main.vue')
    },
    {
        path: '/history',
        name: 'History',
        component: () => import('../views/History.vue')
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error.vue')
    },
    {
        path: '**',
        redirect: '/'
    }
];

const router = new VueRouter({
    base: 'legal',
    routes,
    mode: 'history'
});

export default router;
