<template>
  <div class="custom-select-component" :tabindex="tabindex" v-click-outside="setListDisplay">
    <div class="selected" :class="{ open: open }" @click="checkForRequest( ''); open = true">
      <b-form-input
          @input="checkForRequest($event)"
          v-on:keyup.delete="sendEmptyValue()"
          v-model="selected"
          :placeholder="placeholder"
          required
      ></b-form-input>
    </div>
    <div ref="items" class="items" :class="{ selectHide: open}">
      <div
          class="item"
          :class="{ disabled: option.disabled }"
          v-for="(option, i) of employeeData"
          :key="i"
          @click="setLdap(`${option.surname} ${option.name} ${option.patronymic}`, option.ldap)"
      >
        <div>
          {{ `${option.ldap} ${option.surname} ${option.name} ${option.patronymic}` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
          ? this.default
          : this.options.length > 0
              ? this.options[0].value
              : '',
      open: false,
      employeeData: this.options || []
    };

  },
  mounted() {
    this.$emit("input", this.selected);
  },
  watch: {
    options(data) {
      this.employeeData = data;
      this.open = !!this.employeeData.length;
    }
  },
  methods: {
    checkForRequest(value) {
      if (!this.open || value) {
        this.$emit("change", this.selected);
      }
    },
    setListDisplay(value) {
      return this.open = value;
    },
    sendEmptyValue() {
      this.$emit('input', '');
    },
    setLdap( selected, ldap) {
      this.selected = selected;
      this.open = false;
      this.$emit('input', ldap);
    },
  },
  directives: {
    ['click-outside']: {
      bind(el, binding) {
        el.addEventListener('click', e => e.stopPropagation());
        document.body.addEventListener('click', () => {
          binding.value(false)
        })
      },
      unbind(el, binding) {
        document.body.removeEventListener('click', binding.value);
      }
    }
  }
};
</script>

<style scoped>
  .form-control {
    border: none;
  }
  .form-control:focus {
    box-shadow: none;
  }

  .custom-select-component {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
  }

  .custom-select-component .selected {
    background-color: #fff;
    border-radius: 0;
    border-bottom: 1px solid #464646;
    color: #303030;
    opacity: 0.7;
    cursor: pointer;
    user-select: none;
  }

  .custom-select-component .selected div{
    padding-left: 1em;
    margin-right: 3em;
    overflow: hidden;
    white-space: nowrap;
  }

  .custom-select-component .selected.open {
    overflow: hidden;
    white-space: nowrap;
  }

  .custom-select-component .items {
    background: #FFFFFF;
    border-radius: 3px;
    position: absolute;
    left: 0;
    height: auto;
    overflow: auto;
    max-height: 0;
    z-index: 100;
  }

  .custom-select-component .items.selectHide {
    transition: max-height 0.25s ease-in;
    max-height: 250px;
    border: 1px solid #E6E6E6;
    box-shadow: 3px 4px 12px rgba(94, 1, 1, 0.13);
  }

  .custom-select-component .items .item {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    user-select: none;
    min-width: 300px;
  }

  .custom-select-component .items .item {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    user-select: none;
    min-width: 300px;
  }
  .custom-select-component .items .item.disabled {
    cursor: initial;
  }

  .custom-select-component .items .item div{
    padding-left: 1em;
    margin-right: 1em;
    overflow: hidden;
    white-space: nowrap;
  }

  .custom-select-component .items .item:hover {
    background-color: #00D352;
  }
  .custom-select-component .items .item.disabled:hover {
    background-color: #fff;
  }

</style>
