export const tableParamsForRequest = {
    accounts: 'account',
    cards: 'pan',
    loans: 'odbContractNum',
    deposits: 'contractRef',
    closed_deposits: 'contractRef',
    open_deposits: 'contractRef',
    open_cards: 'pan',
    closed_cards: 'pan',
    credits: 'contractRef',
    openedCredits: 'odbContractNum',
    closedCredits: 'contractRef',
}
