export const TABLE_NAMES_BY_TABLE_PARAMS = {
    cards: 'cards_table',
    accounts: 'accounts_table',
    deposits: 'deposits_table',
    credits: 'loans_table',
    loans: 'loans_table',
    openDeposits: 'open_deposits_table',
    closedDeposits: 'closed_deposits_table',
    openCards: 'open_cards_table',
    closedCards: 'closed_cards_table',
    openedCredits: 'open_credits_table',
    closedCredits: 'closed_credits_table'
}

export const ELEMENTS_NAME_BY_TABLE_PARAMS = {
    closedDeposits: 'deposits_tabs',
    openDeposits: 'deposits_tabs',
    openCards: 'cards_tabs',
    closedCards: 'cards_tabs',
    openedCredits: 'credits_tabs',
    closedCredits: 'credits_tabs'
}
