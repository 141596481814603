export const REQUEST_PARAMS = {
    SURNAME: 'surname',
    NAME: 'name',
    PATRONYMIC: 'patronymic',
    LDAP: 'ldap',
    WITHSTAMP: 'withStamp',
    DATE_TO: 'date_to',
    DATE_FROM: 'date_from',
    DATE_PERIOD: 'date_period',
    DATE: 'date',
    CERTIFICATE_TYPE: 'certificate_type',
}
