export const DATE_RANGE_CONFIG = {
    lang: {
        formatLocale: {
            // MMMM
            months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
            // MMM
            monthsShort: ['Січ', 'Лют', 'Бер', 'Кві', 'Трав', 'Чер', 'Лип', 'Сер', 'Вер', 'Жовт', 'Лис', 'Груд'],
            // dddd
            weekdays: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота'],
            // ddd
            weekdaysShort: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            // dd
            weekdaysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            // first day of week
            firstDayOfWeek: 1,
            // first week contains January 1st.
            firstWeekContainsDate: 1,
            // format 'a', 'A'
        },
        // the calendar header, default formatLocale.weekdaysMin
        days: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        // the calendar months, default formatLocale.monthsShort
        months: ['Січ', 'Лют', 'Бер', 'Кві', 'Трав', 'Чер', 'Лип', 'Сер', 'Вер', 'Жовт', 'Лис', 'Груд'],
        // the calendar title of year
        yearFormat: 'YYYY',
        // the calendar title of month
        monthFormat: 'MMM',
        // the calendar title of month before year
        monthBeforeYear: false,
    },
    format: 'DD.MM.YYYY',
    separator: ' - ',
    defaultValue: [new Date(), new Date()]
}
