<script>
import '../styles/physical/index.scss'

export default {
  data() {
    return {}
  },
  mounted() {
    document.body.classList.remove('legal-container');
    document.body.classList.add('physical-container');
  }
}
</script>

<template>
  <div class="container-fluid physical">
    <router-view></router-view>
  </div>
</template>
