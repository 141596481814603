<template>
  <b-modal
      ref="modal"
      :id="element.parameter"
      hide-footer
      :title="element.caption">
    <div v-for="item in element.elements" :key="item.name || item.type">
      <form v-if="item.type === 'input'" :ref="item.name" @submit.stop.prevent="handleSubmitModal">
        <b-form-group
            :label="element.text"
            label-for="email-input"
            :invalid-feedback="email.message"
            :state="email.state"
        >
          <b-form-input
              id="email-input"
              v-model="emailValue"
              :state="email.state"
              :placeholder="item.placeholder"
              required
          ></b-form-input>
        </b-form-group>
      </form>
      <div v-if="item.type === 'icon'" :class="item.type">
        <div :class="`${item.type}-title`">{{ item.label }}</div>
        <div :class="`${item.type}-body`">
          <img :src="pdf_icon" alt="pdf_icon">
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div class="info">{{ element.info }}</div>
    <b-button @click="handleOkModal(element.parameter)">{{ element.elements.button.name }}
    </b-button>
  </b-modal>
</template>

<script>
import RequestService from "@/physical/services/request.service";
import pdf_icon from "@/assets/image/pdf_icon.svg";

export default {
  name: "EmailModal",
  props: {
    element: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      email: {
        state: null,
        message: ''
      },
      emailValue: '',
      pdf_icon: pdf_icon,
      reqExp: /^([A-za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*\.[A-Za-z]{2,6}$/,
    }
  },
  mounted() {
    this.resetModal();
  },
  methods: {
    resetModal() {
      this.email.state = null
      RequestService.getEmail().then(({data}) => {
        this.emailValue = data.email;
      })
      .catch(() => {
      })
    },
    handleOkModal(type) {
      this.handleSubmitModal(type)
    },
    handleSubmitModal(type) {
      if (!this.checkFormModalValidity(type)) {
        return
      }
      this.$nextTick(() => {
        this.$bvModal.hide(type);
        this.$emit("change", this.emailValue);

      })
    },
    checkFormModalValidity(type) {
      const valid = this.reqExp.test(this.emailValue) && this.$refs[type][0].checkValidity();
      this[type].state = valid;
      this[type].message = this.getEmailErrMessage();
      return valid
    },
    getEmailErrMessage() {
      const errors = this.element.elements.email_input.errors;
      return this.emailValue.length > 0 ? errors.format : errors.required
    },
  }
}
</script>

<style lang="scss">
  .modal-dialog {
    .modal-content {
      .modal-header {
        justify-content: center;
        align-items: center;
        border-bottom: none;
        padding: 1rem 0.5rem 0;
        .modal-title {
          padding-left: 1rem;
          width: 100%;
          text-align: center;
          font-weight: bold;
          font-size: 21px;
        }
        .close {
          padding: 0 1rem 2.5rem;
          margin: 0;
        }
      }
      .modal-body {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0.5rem 4.5rem 4rem;
        div {
          width: 100%;
        }
        .form-group {
          label[for="email-input"] {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 0;
          }
          input {
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            &.is-invalid {
              border-color: #dc3545;
            }
          }
          input:focus {
            box-shadow: none;
            border-color: #00D352;
          }
          input::placeholder {
            opacity: 0.3;
          }
          input {
            &.is-invalid:focus {
              box-shadow: none;
              border-color: #dc3545;
            }
          }
          .form-control {
            border-radius: 0;
          }
        }
        .icon {
          &-body {
            display: flex;
            img {
              height: 40px;
            }
            p {
              line-height: 45px;
              font-weight: 600;
              margin: 0 0 0 10px;
              align-items: center;
            }
          }
          &-title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        .info {
          font-style: italic;
          font-size: 13px;
          line-height: 16px;
          width: 80%;
          text-align: center;
          margin: 20px auto;
          color: rgba(0, 0, 0, 0.6);
        }
        .btn {
          padding: 13px 60px 13px;
          cursor: pointer;
          color: #fff;
          box-shadow: none;
          background: #00D352;
          opacity: 0.8;
          border-radius: 30px;
          font-size: 17px;
          font-weight: bold;
          border: 1px solid #00D352;

        }
        .btn:hover {
          color: #fff;
          background-color: #00D352;
          border-color: #00D352;
        }
      }
    }
    .cell.selected {
      background: #00D352 !important;
    }
  }
</style>
