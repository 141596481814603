import RequestService from '../services/request.service';
import ViewError from '../../components/ViewError';
import {tableParamsForRequest} from '../enums/tableParamsForRequest.enum.ts';
import CustomSelect from '../components/custom-select.component/CustomSelect';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import {uk} from 'vuejs-datepicker/dist/locale';
import CustomAutocompleteSelect from '@/physical/components/custom-autocomlete-select/CustomAutocompleteSelect';
import Spinner from '@/physical/components/spinner/Spinner';
import {CERTIFICATE_TYPES} from '@/physical/enums/certificateTypes.enum.ts';
import {ELEMENTS_NAME_BY_TABLE_PARAMS, TABLE_NAMES_BY_TABLE_PARAMS} from '@/physical/enums/tableNames.enum.ts';
import CustomTable from '@/physical/components/custom-table/CustomTable';
import EmailModal from '@/physical/components/email-modal/EmailModal';
import DateRangePicker from '@/physical/components/date-range-picker/DateRangePicker';
import {DATE_PERIOD_ELEMENTS} from '@/physical/enums/datePeriodElements';
import 'moment/locale/uk';
import {REQUEST_PARAMS} from '@/physical/enums/requestParamsNames';
import {DATE_RANGE_CONFIG} from '@/physical/enums/date-range.config';

export default {
    name: 'Main',
    components: {
        ViewError,
        CustomSelect,
        CustomAutocompleteSelect,
        Spinner,
        Datepicker,
        CustomTable,
        EmailModal,
        DateRangePicker
    },
    data() {
        return {
            activeTab: 'tab1',
            loading: true,
            loadingSmall: false,
            docLoading: false,
            opacity: '0.7',
            initData: {},
            requestData: {},
            elements: {},
            navItems: {},
            tabs: [],
            errors: {},
            selectedTableParam: {},
            quarter: {},
            quarterOptions: {},
            quarterOptionsDefault: {},
            paramsArr: [],
            tablesParamsNames: [],
            tableRequestParamsByTableNames: {
                cards: {
                    name: 'cards',
                    link: RequestService.getCards.bind(this)
                },
                accounts: {
                    name: 'accounts',
                    link: RequestService.getAccounts.bind(this)
                },
                deposits: {
                    name: 'deposits',
                    link: RequestService.getDeposits.bind(this)
                },
                loans: {
                    name: 'credits',
                    link: RequestService.getCredits.bind(this)
                },
                credits: {
                    name: 'credits',
                    link: RequestService.getCredits.bind(this)
                }
            },
            tableNamesByTableParams: TABLE_NAMES_BY_TABLE_PARAMS,
            elementsNamesByTableParams: ELEMENTS_NAME_BY_TABLE_PARAMS,
            paramsTypeForGetValue: tableParamsForRequest,
            datePeriodButtons: DATE_PERIOD_ELEMENTS,
            requestParams: REQUEST_PARAMS,
            includeTables: false,
            showRightSide: false,
            tableParam: '',
            isTableDataSelected: false,
            selectedTableParams: [],
            showRequisitesStatus: false,
            withStamp: 0,
            employeeData: [],
            dateValue: this.moment(),
            minDate: new Date(1991,0,1, 0, 0, 0, 0),
            state: {
                language: uk,
                disabledDates: {
                    to: new Date(2020, 0, 1), // Disable all dates up to specific date
                    from: new Date(), // Disable all dates up to specific date
                },
            },
            dateStart: '',
            dateFinish: '',
            certificateTypes: CERTIFICATE_TYPES,
            loadings: {},
            loadingsChecker: true,
            ldapRequestValue: '',
            noDataTitle: '',
            config: DATE_RANGE_CONFIG,
            defaultTabs: ['tab1', 'tab2']
        };
    },
    mounted() {
        RequestService.getInitData()
            .then(({data}) => {
                this.initData = data;
                this.setAdditionalRequestData(data);
            })
            .catch(() => {

            })
            .finally(() => {
                this.loading = false;
            });
    },
    watch: {
        loadingsChecker() {
            return true
        }
    },
    computed: {
        date: {
            get() {
                return this.dateValue
            },
            set(value) {
                this.dateValue = this.customFormatter(value);
                this.requestData['date'] = this.customFormatter(value);
            }
        },
        datePeriod: {
            get() {
                return [this.dateStart, this.dateFinish]
            },
            set(value) {
                this.dateStart = value[0];
                this.dateFinish = value[1];
                this.requestData['date_from'] = this.dateStart ? this.customFormatter(this.dateStart) : this.dateStart;
                this.requestData['date_to'] = this.dateFinish ? this.customFormatter(this.dateFinish) : this.dateFinish;
            }
        }
    },
    methods: {
        changeTab(tab, parameter) {
            this.activeTab = tab;
            this.resetTabTable(parameter);
        },
        resetTabTable(parameter) {
            this.isTableDataSelected = false;
            this.selectedTableParams = [];
            delete this.requestData[parameter];
        },
        issetError(key) {
            return this.errors[key];
        },
        clearDateRange() {
            this.dateStart = '';
            this.dateFinish = '';
            this.requestData.date_from = '';
            this.requestData.date_to = '';
            this.datePeriod.startDate = null;
            this.datePeriod.endDate = null;
            this.dStart = '';
        },
        /**
         * Ограничение выбора даты
         */
        dateFormat (classes, date) {
            let period = {
                value: 1,
                valueN: -1,
                type: 'years'
            };
            if (this.requestData.certificate_type === 'corporate_client_statement') {
                period = {
                    value: 3,
                    valueN: -3,
                    type: 'months'
                };
            }
            if (!classes.disabled) {
                classes.disabled = moment(this.$refs['range-datepicker'][0].end).diff(date, period.type, true) >= period.value ||
                    moment(this.$refs['range-datepicker'][0].start).diff(date, period.type, true) <= period.valueN;
            }
            return classes
        },
        getCertificateType(type) {
            return this.requestData.certificate_type === type;
        },
        getDatePickerHeaderYear() {
            if (this.$refs.datepicker && this.$refs.datepicker.length) {
                return this.dateFormatter(this.$refs.datepicker[0].selectedDate, "YYYY")
            }
            return '-'
        },
        getDatePickerHeaderText() {
            if (this.$refs.datepicker && this.$refs.datepicker.length) {
                return this.dateFormatter(this.$refs.datepicker[0].selectedDate, "dddd D MMMM")

            }
            return '-'
        },
        dateFormatter(date, format) {
            return moment(date, 'DD-MM-YYYY').local('uk').format(format);
        },
        setAdditionalRequestData(data) {
            if (data.tabs && data.order_certificate.elements) {
                this.tabs = data.tabs;
                for (const element in data.order_certificate.elements.certificate_type) {
                    if (Object.getPrototypeOf(data.order_certificate.elements.certificate_type[element]) === Object.prototype) {
                        this.navItems[element] = Object.assign(data.order_certificate.elements.certificate_type[element], {name: element});
                    }
                }
                for (const element in data.order_certificate.elements) {
                    if (Object.prototype.hasOwnProperty.call(data.order_certificate.elements, element)) {
                        this.requestData[element] = '';
                    }
                }
            }
        },
        changeRequestData(type) {
            this.resetAllData(type);
            this.getAdditionElements();
        },
        resetAllData(type) {
            this.errors = {};
            this.requestData = {certificate_type: type};
            this.elements = {};
            this.quarter = {};
            this.title = '';
            this.noDataTitle = '';
            this.selectedTableParam = {};
            this.paramsArr = [];
            this.tablesParamsNames = [];
            this.selectedTableParams = [];
            this.isTableDataSelected = false;
        },
        getAdditionElements() {
            this.loadingSmall = true;
            this.showRightSide = false;
            RequestService.getAdditionElements(this.requestData)
                .then(({data}) => {
                    this.title = data.title;
                    this.noDataTitle = data.no_data_label;
                    let count = 0;
                    for (const element in data) {
                        if (data[element]?.type === 'table') {
                            ++count
                        }
                        if (Object.getPrototypeOf(data[element]) === Object.prototype) {
                            this.elements[element] = data[element];
                            if (data[element].type === 'table') {
                                this.paramsArr.push(data[element].parameter)
                                this.tablesParamsNames.push(this.tableRequestParamsByTableNames[data[element].name].link)
                                if (this.getCertificateType(this.certificateTypes.REFERENCE_ACCOUNTS)) {
                                    this.loadings[data[element].name] = true;
                                    this.loadingsChecker = element.name;
                                    this.getTablesForReferenceAccounts(data[element].name, element);
                                }
                            } else if (data[element].type === 'select') {
                                if (data[element].name === 'currency') {
                                    this.requestData[data[element].name] = data[element].data.find(el => el.value === '980').value;
                                } else {
                                    this.requestData[data[element].name] = data[element].data[0].value;
                                }

                            } else if (data[element].type === 'quarter') {
                                this[data[element].type] = {
                                    [data[element].elements.quarter.name]: data[element].elements.quarter.data[0],
                                    [data[element].elements.year.name]: data[element].elements.year.data[0].value,
                                    parameters: data[element].parameters
                                };
                                const options = data[element].elements.quarter.data;
                                this.quarterOptionsDefault = options.map(item => {
                                    return {
                                        value: {value_from: item.value_from, value_to: item.value_to},
                                        label: item.label,
                                    }
                                })
                                this.quarterOptions = this.quarterOptionsDefault
                                    .filter((elem, index) => !this.checkQuarterField(elem, this.quarterOptionsDefault[index + 1]));
                            } else if (data[element].type === 'modal') {
                                this.requestData[data[element].parameter] = '';
                                this.elements[element]['errors'] = data[element].elements.email_input.errors;
                            } else if (data[element].type === 'checkbox') {
                                if (data[element].name === 'show_balance') {
                                    this.requestData[data[element].name] = true;
                                }
                            } else if (data[element].type === 'date') {
                                this.requestData[data[element].name] = this.date;
                            } if (data[element].type === 'tabs') {
                                ++count
                                this.paramsArr.push(data[element].parameter)
                                this.tablesParamsNames.push(this.tableRequestParamsByTableNames[data[element].name].link)
                            }
                        }
                    }
                    this.includeTables = !!count;
                })
                .catch(() => {
                })
                .finally(() => {
                    this.loadingSmall = this.includeTables && !this.getCertificateType(this.certificateTypes.REFERENCE_ACCOUNTS);
                    this.showRightSide = !this.includeTables || this.getCertificateType(this.certificateTypes.REFERENCE_ACCOUNTS);
                    if (this.includeTables && !this.getCertificateType(this.certificateTypes.REFERENCE_ACCOUNTS)) {
                        this.getTablesForAllPagesExceptReferenceAccounts();
                    }
                })
        },
        setTableRequestParam(parameter, value, multiply) {
            this.isTableDataSelected = !!value.length;
            this.selectedTableParams = [];
            if (multiply) {
                (!value || !value.length) ? delete this.requestData[parameter] : this.requestData[parameter] = value;
            }
            for (const param of this.paramsArr) {
                if (!multiply || this.requestData.certificate_type !== this.certificateTypes.REFERENCE_ACCOUNTS) {
                    (parameter === param) ? this.requestData[parameter] = value : delete this.requestData[param];
                } else {
                    if (this.requestData[param]) {
                        this.selectedTableParams.push(param);
                    }
                }
            }
        },
        setModalParam(parameter, value) {
            this.requestData[parameter] = value;
            this.sendRequest();
        },
        getTablesForReferenceAccounts(type, element) {
            this.tableRequestParamsByTableNames[type].link(this.requestData)
                .then(({data}) => {
                    if (data[this.tableRequestParamsByTableNames[type].name].length > 0) {
                        this.elements[element]['value'] = data[this.tableRequestParamsByTableNames[type].name];
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.errors[type] = error.response.data.message;
                    }
                })
                .finally(() => {
                    this.loadings[type] = false;
                    this.loadingsChecker = type;
                });
        },
        getTablesForAllPagesExceptReferenceAccounts() {
            Promise.allSettled(this.tablesParamsNames.map((endpoint) => endpoint(this.requestData)))
                .then((data) => {
                    this.loadingSmall = true;
                    for (const item of data) {
                        if (item.status === 'fulfilled') {
                            this.setTableValues(item);
                        } else {
                            this.errors[this.requestParams.CERTIFICATE_TYPE] = item.reason.response.data.message;
                        }
                    }
                })
                .finally(() => {
                    this.showRightSide = true;
                    this.loadingSmall = false;
            });
        },
        setTableValues(requestValue) {
            for (const [key, value] of Object.entries(requestValue.value.data)) {
                const table = this.elements[this.tableNamesByTableParams[key]];
                if (Array.isArray(value) && value.length > 0) {
                    if (table) {
                        table.value = value;
                    } else {
                        this.setTabsTableValue(value, key);
                    }
                }

            }
        },
        setTabsTableValue(fieldValue, fieldName) {
            for (const tabKey of this.defaultTabs) {
                const tabTable = this.elements[this.elementsNamesByTableParams[fieldName]][tabKey][this.tableNamesByTableParams[fieldName]];
                tabTable ? tabTable.value = fieldValue : null
            }
        },
        moment(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        checkForRequiredTableData() {
            for (const key in this.elements) {
                if (this.elements[key].type === 'table' && ((this.requestData[this.elements[key].parameter] &&
                        this.requestData[this.elements[key].parameter].length > 0) ||
                    (this.elements[key].required_without  && this.checkRequiredWithout(this.elements[key].required_without)))) {
                    return false;
                }
            }
            return true;
        },
        checkRequiredWithout(requiredWithout) {
            requiredWithout.forEach((item) => {
                if(this.requestData[item] && this.requestData[item].length > 0) {
                    return true;
                }
            });
            return false;
        },
        checkRequiredParams() {
            const tablePram = (this.checkForElement('type','table') || this.checkForElement('type', 'tabs')) ? !this.isTableDataSelected : false;
            const dateParam = this.checkForElement('type', this.requestParams.DATE) ? !this.requestData[this.requestParams.DATE] : false;
            const datePeriod = this.checkForElement('type', this.requestParams.DATE_PERIOD) ? (!this.requestData[this.requestParams.DATE_FROM] && !this.requestData[this.requestParams.DATE_TO]) : false;
            const ldap = this.checkForElement('name',this.requestParams.WITHSTAMP) && !this.requestData[this.requestParams.WITHSTAMP] ? !this.requestData[this.requestParams.LDAP] : false;
            return tablePram || dateParam || datePeriod || ldap;
        },
        isNeedShowNoDataTitle() {
            if (this.checkForElement('type', 'table') && this.showRightSide) {
                return this.checkForTablesData();
            } else {
                return false;
            }
        },
        checkForTablesData() {
            let isShow = true;
            for (const key in this.elements) {
                if (this.elements[key].type === 'table' && this.elements[key].value) {
                    return isShow = false;
                }
            }
            return isShow;
        },
        isNeedShowElementsUnderTabTables() {
            let isShow = false;
            const tabs = this.checkForElement('type', 'tabs');
            if (tabs) {
                for (const key in tabs[1][this.activeTab]) {
                    (this.checkForElement('type', 'tabs')[1][this.activeTab][key].type === 'table' &&
                        this.checkForElement('type', 'tabs')[1][this.activeTab][key].value) ? isShow = true : null;
                }
            } else {
                isShow = true;
            }
            return isShow;
        },
        getLabel() {
            if (this.title) {
                return this.title;
            } else {
                return this.initData.order_certificate.elements.certificate_type.label;
            }
        },
        checkForElement(type, value) {
            return Object.entries(this.elements).find(el =>  el[1][type] === value);
        },
        checkRequisitesStatus(param) {
            if (this.selectedTableParams.length > 1) {
                this.requestData[param] = false;
                return true;
            } else if (this.selectedTableParams[0] && this.requestData[this.selectedTableParams[0]].length !== 1) {
                this.requestData[param] = false;
                return true;
            } else {
                return false;
            }
        },
        setWithStamp(elementName) {
            if (elementName === this.requestParams.WITHSTAMP) {
                this.withStamp = this.requestData[elementName]
            }
        },
        checkQuarterField(item, nextItem) {
            const value = item.value.value_to;
            const nextValue = nextItem ? item.value.value_to : null;
            if (value && nextValue) {
                return moment().isBefore(`${this.quarter.year}-${value}`) &&
                    !moment().isBetween(`${this.quarter.year}-${nextValue}`, `${this.quarter.year}-${value}`);
            }
            return false;
        },
        processRequest(isEmail) {
            if (Object.keys(this.quarter).length) {
                this.requestData[this.quarter.parameters.date_from.name] = `${this.quarter.year}-${this.quarter.quarter.value_from}`;
                this.requestData[this.quarter.parameters.date_to.name] = `${this.quarter.year}-${this.quarter.quarter.value_to}`;
            }
            if(this.requestData[this.requestParams.WITHSTAMP] === 1) {
                delete this.requestData[this.requestParams.LDAP];
            }
            if (isEmail) {
                this.$bvModal.show('email')
            } else {
                delete this.requestData.email;
                this.sendRequest();
            }
        },
        sendRequest() {
            this.docLoading = true;
            RequestService.getDocument(this.requestData)
                .then(({data}) => {
                    if (data.link) {
                        window.open(data.link);
                    }
                    if (data.message) {
                        this.$toast.open({
                            message:data.message,
                            type: 'success',
                        })
                    }
                })
                .catch(err => {
                    if (err.errors) {
                        for (const error in err.errors) {
                            this.$toast.open({
                                message: err.errors[error][0],
                                type: 'error',
                            })
                        }
                        this.errors = err.errors;
                    } else {
                        this.errors = err;
                    }
                })
                .finally(() => {
                    this.docLoading = false;
                })
        },
        setSearchEmployeeParams(value) {
            this.employeeData = [];
            const requestData = {};
            const regexp = /[а-яґёїі]/i;
            const regexpNum = /[a-z\d]/i;
            if (regexp.test(value) && value.length > 1) {
                this.generateEmployeeNameRequest(requestData, value);
            } else if (regexpNum.test(value) && value.length === 11) {
                requestData['ldap'] = value;
                this.searchEmployee(requestData);
            }
        },
        generateEmployeeNameRequest(requestData, value) {
            const indexOfSurname = value.indexOf(' ');
            if (indexOfSurname !== -1) {
                requestData[this.requestParams.SURNAME] = this.getEmployeeParams(value, indexOfSurname);
                const nameValue = value.slice(indexOfSurname + 1);
                const indexOfName = nameValue.indexOf(' ');
                if (nameValue.length > 1 && indexOfName !== -1) {
                    requestData[this.requestParams.NAME] = this.getEmployeeParams(nameValue, indexOfName);
                    const patronymicNameValue = nameValue.slice(indexOfName + 1);
                    (patronymicNameValue.length > 1) ? requestData[this.requestParams.PATRONYMIC] = patronymicNameValue : requestData[this.requestParams.NAME] = nameValue;
                }
            } else {
                requestData[this.requestParams.SURNAME] = value;
            }
            this.searchEmployee(requestData);
        },
        getEmployeeParams(value, index) {
            return value.slice(0, index);
        },
        searchEmployee(requestData) {
            this.ldapRequestValue = requestData;
            RequestService.searchEmployee(requestData).then(({data}) => {
                if (this.ldapRequestValue === requestData) {
                    this.employeeData = Object.values(data.employees);
                }
            }).catch((error) => {
                this.employeeData = [];
                this.errors.ldap = [ error.response.data.message ];
            });
        },
        checkLdapErrors() {
            (!this.requestData[this.requestParams.LDAP].length) ? this.errors.ldap = [ this.elements.withStamp.elements.ldap.error_required ] : delete this.errors.ldap;
        },
        rearrangeQuarters(nameField) {
            if (nameField === 'year') {
                this.quarterOptions = this.quarterOptionsDefault
                    .filter((elem, index) => {
                        return !this.checkQuarterField(elem, this.quarterOptionsDefault[index + 1]);
                    })
            }
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        isSeparateEmailBtn() {
            return this.requestData.certificate_type !== 'income_statement_new';
        }
    }
}
