<template>
<div>
  <p v-for="(error, index) in errors" :key="index">{{error}}</p>
</div>
</template>

<script>
export default {
  name: 'ViewError',
  props: {
    errors: Array
  }
}
</script>

<style scoped>
p {
  font-size: 12px;
  margin-bottom: 0;
  color: #dc3545;
}
</style>
