import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from './config/axios';
import {
    ButtonPlugin,
    CollapsePlugin,
    FormCheckboxPlugin,
    FormInputPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    ListGroupPlugin,
    OverlayPlugin,
    TabsPlugin,
    TablePlugin,
    ModalPlugin,
    FormGroupPlugin,
    DropdownPlugin
} from 'bootstrap-vue';
import VueToast from 'vue-toast-notification';
import LegalApp from './legal/LegalApp.vue';
import PhysicalApp from "@/physical/PhysicalApp";
import PhysicalRouter from './physical/router';
import LegalRouter from './legal/router';
import VueDatePicker from '@mathieustan/vue-datepicker';
import 'vue-toast-notification/dist/theme-default.css';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

Vue.config.productionTip = false;

// const location = window.location;

const Application = location.pathname.includes('/legal') ? LegalApp : PhysicalApp;
const router = location.pathname.includes('/legal') ? LegalRouter : PhysicalRouter;


Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(FormInputPlugin);
Vue.use(ButtonPlugin);
Vue.use(TabsPlugin);
Vue.use(CollapsePlugin);
Vue.use(ListGroupPlugin);
Vue.use(OverlayPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin);
Vue.use(TablePlugin);
Vue.use(VueToast);
Vue.use(ModalPlugin);
Vue.use(FormGroupPlugin);
Vue.use(VueDatePicker);
Vue.use(DropdownPlugin);

new Vue({
    router,
    render: h => h(Application),
}).$mount('#app');
